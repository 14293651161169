<!--
    @name: customWord
    @description：customWord
    @author: ZengWei
    @date: 2022-04-24 09:49
-->
<template>
  <div style="height: 100%" v-loading="addLoading">
    <div v-if="!isAdd" class="export-template">
      <div class="left-nav">
        <div
          :class="['left-nav-item',{active:activeItem === index}]"
          v-for="(item, index) in leftList"
          :key="index"
          @click="changeItem(item.id,index)">
          <div class="name">{{item.name}}</div>

          <el-dropdown class="action-icon" v-show="activeItem === index">
          <span class="el-dropdown-link">
            <i class="iconfont iconbimgis_gengduo"></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p @click.stop="editName(item)">编辑</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click.stop="delName(item.id)">删除</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="left-nav-item" @click="addTemp" >
          <div><i class="iconfont iconxinzeng1"></i> 新增模板</div>
        </div>
      </div>
      <div class="content">
        <template v-if="rightData">
          <div class="article-title">
            {{rightData.name}}
          </div>
          <!--右侧的操作信息-->
          <div class="article-first">
            <div class="article-content-title">
              模板文件
            </div>
            <div class="article-first-row">
              <div>
                <span class="label-title">文件名称：</span>
                <a :href="baseUrl + '/' + rightData.word_template_path" class="label-title-content">
                  {{rightData.word_template_path ? rightData.name + '.doc' : '&nbsp;'}}</a>
              </div>
              <div class="article-first-action">
                <span @click="openFile(rightData.word_template_path,rightData.name)">在线查看</span>
                <span @click="updateFile">重新上传</span>
                <!-- <a href="#scrollTop">更新记录</a> -->
              </div>
            </div>
            <div class="article-second-row">
              <!--<div>-->
              <!--<span class="label-title">上传人：</span>-->
              <!--<span class="label-title-content">{{rightData.name}}.doc</span>-->
              <!--</div>-->
              <div>
                <span class="label-title">上传时间：</span>
                <span class="label-title-content">{{rightData.updated_at ? rightData.updated_at.substring(0,16 ) : '-'}}</span>
              </div>
            </div>
          </div>
          <!--右侧表格栏-->
          <div class="mytable-scrollbar">
            <div class="article-second" style="height: calc(100vh - 325px);overflow: auto;padding-bottom: 10px" >
              <div>
                <div class="article-content-title">模版内容与元素对照表</div>
                <div style="width: 500px;padding: 10px 46px 20px">


                  <el-table
                    :data="rightData.fields"
                    border
                    style="width: 100%">
                    <el-table-column label="序号" width="50">
                      <template slot-scope="scope">
                        {{scope.$index+1}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="元素" width="180"></el-table-column>
                    <el-table-column prop="field" label="field值"></el-table-column>
                    <el-table-column label="字符串"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty v-else style="height: 100%" description="请先选择左侧模板"></el-empty>
      </div>
    </div>
    <div v-if="isAdd" class="switch-show">
      <div>
        <img src="@/assets/images/flow.jpg" alt="">
        <p v-if="isAdd">暂未绑定功能表单或者请创建功能表单！</p>
      </div>
    </div>
    <el-dialog
      :title="showTitle"
      :visible.sync="showModal"
      width="30%">
      <div v-if="showModal">
        <el-form ref="bankRuleForm" :model="formOperate" :rules="ruleOperate" label-width="120px">
          <el-form-item label="模板名称：" prop="name">
            <el-input v-model="formOperate.name"></el-input>
          </el-form-item>
          <el-form-item label="是否默认：" prop="is_default">
            <el-switch v-model="formOperate.is_default"></el-switch>
          </el-form-item>
          <el-form-item label="模板上传：">
            <uploader
              v-if="!editFlag || fileUpFlag"
              @extra-files="extraFiles"
              :fileTypeList="['.doc','.docx']">
            </uploader>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button type="primary" @click="submitTemplateForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  wordTemplateListApi,
  wordTemplateAddApi,
  wordTemplateDetailApi,
  wordTemplateEditApi,
  wordTemplateDelApi
} from "@/apis/data/customForm";
import { baseUrl } from '@/apis/http/request';
import uploader from "@/components/fileUpload/newFiles/index";
import {Empty} from "element-ui";
import {dataInterface} from "@/apis/data";
import {openUrl} from '@/utils/tools'
export default {
  name: "customWord",
  components: {
    uploader,
    'el-empty': Empty
  },
  props: {
    tableInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return{
      design_id: '',
      leftList: [],//模板列表
      rightData: null,//选中的数据
      showModal: false,
      showTitle: '新增模板类型',
      formOperate: {
        name: '',
        design_id: '',
        is_default: 0,
        word_template_path: ''
      },
      ruleOperate: {
        name: [{required: true, message: '请输入模板名称'}]
      },
      activeItem: '',
      midId: '',
      editFlag: false,
      fileUpFlag: false,
      baseUrl: baseUrl,
      addLoading: false,
      isAdd: true
    }
  },
  mounted(){
    this.getInfo()
  },
  methods: {
    async getInfo() {
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object626118627602e",
        transcode: 0,
        data_id: this.tableInfo.id,
      };
      this.addLoading = true
      this.detailInfo = await this.dataInterface(params)
      if (this.detailInfo.form_design_id) {
        this.isAdd = false
        this.addLoading = false
        this.design_id = this.detailInfo.form_design_id
        this.getLeftList()
      } else {
        this.isAdd = true
        this.addLoading = false
      }
    },
    // 请求数据id的详情
    async dataInterface(params) {
      let info = await dataInterface(params);
      return info.data.data
    },
    updateFile(){
      const {name,word_template_path,is_default,design_id} = this.rightData
      this.formOperate = {
        name,
        word_template_path,
        is_default,
        design_id
      }
      this.fileUpFlag = true
      this.showTitle = '重新上传模板'
      this.showModal = true
    },
    addTemp(){
      this.showModal=true
      this.showTitle = '新增模板类型'
      this.editFlag = false
      this.fileUpFlag = false
      this.formOperate={
        name:'',
        design_id:this.design_id,
        is_default:0,
        word_template_path:''
      }
    },
    editName(item){
      console.log(item,'item');
      const {name,word_template_path,is_default,design_id} = item
      this.formOperate = {
        name,
        word_template_path,
        is_default:is_default==1?true:false,
        design_id
      }
      console.log(this.formOperate,'formOperate');
      this.fileUpFlag= false
      this.editFlag = true
      this.showModal = true
    },
    delName(id){
      wordTemplateDelApi(id).then(res=>{
        if(res && res.data.code == 200){
          this.getLeftList()
          this.rightData = null
        }
      })
    },
    openFile(path, name){
      if (path) {
        // window.open('openFile.html?url='+baseUrl+'/'+ path +'&fileName=' + name)
        const urls = 'openFile.html?url='+baseUrl+'/'+ path +'&fileName=' + name
        openUrl(urls,urls)
      }else {
        this.$Message.info('暂无查看文件！')
      }
    },
    // 中间栏的切换事件
    changeItem(id,index){
      this.activeItem=index;
      if (id !== this.midId) {
        this.midId=id;
        this.getDetail();
      }
    },
    getDetail(){
      wordTemplateDetailApi(this.midId).then(res=>{
        if(res && res.data.code == 200){
          let data = res.data.data
          this.rightData = data
        }
      })
    },
    extraFiles(data){
      const filePath = data[0]?.filepath || ''
      if(filePath){
        this.formOperate.word_template_path=data[0].filepath
      }
    },
    submitTemplateForm(){
      this.formOperate.is_default = this.formOperate.is_default?1:0
      if(this.fileUpFlag){
        wordTemplateEditApi(this.formOperate,this.midId).then(res=>{
          if(res && res.data.code ==200){
            this.showModal = false
            this.rightData = res.data.data
            this.getLeftList()
          }
        })
      }else{
        this.$refs.formOperate.validate(valid=>{
          if(valid){
            if(this.formOperate.word_template_path === ''){
              this.$Message.error('请上传模板')
              return
            }
            if(this.editFlag){
              wordTemplateEditApi(this.formOperate,this.midId).then(res=>{
                if(res && res.data.code ==200){
                  this.showModal = false
                  this.rightData = res.data.data
                  this.getLeftList()
                }
              })
            }else{
              wordTemplateAddApi(this.formOperate).then(res=>{
                if(res && res.data.code == 200){
                  this.showModal = false
                  this.getLeftList()
                }
              })
            }
          }
        })
      }

    },
    getLeftList(){
      wordTemplateListApi({design_id:this.design_id}).then(res=>{
        if(res && res.data.code == 200){
          let data = res.data.data
          this.leftList = data
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.switch-show {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    color: #999;
    font-size: 16px;
    text-align: center;
    margin: 5px 0;
  }

  .button-class {
    margin-top: 10px;
  }
}

.export-template{
  width: 100%;
  height: 100%;
  display: flex;
  .left-nav{
    height:100%;
    width: 208px;
    background-color: #fff;
    border-right: 1px solid #EAEBED;
    .left-nav-item{
      color: #0076FF;
      margin-top: 10px;
      font-size: 14px;
      line-height: 14px;
      padding: 16px;
      cursor: pointer;
      color: #25282E;
      display: flex;
      justify-content: space-between;
      .name{
        flex: 1;
      }
      &:hover{
        background: rgba(194, 197, 204, 0.08);
        .action-icon{
          display: inline-block!important;
        }
      }
      .action-icon{
        width: 24px;
        text-align: right;
      }
    }
    .active{
      background-color: rgba(0, 118, 255, 0.05);
      color: var(--themeColor);
      font-weight: bold;
    }
  }
  .content{
    color: #25282E;
    background-color: #fff;
    overflow: hidden;
    flex: 1;
    .article-title{
      font-size: 16px;
      color: #25282E;
      line-height: 16px;
      padding: 27px 32px;
      font-weight: bold;
      border-bottom: 1px solid #EAEBED;

    }
    .article-content-title{
      padding: 24px 32px 14px;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
    }
    .article-first{
      border-bottom: 1px solid #EAEBED;
    }
    .article-first-row{
      padding: 10px 46px;
      display: flex;
      justify-content: space-between;
      a{
        color: #25282E;
        text-decoration: underline ;
      }
      .label-title{
        color: #7D818A;
      }
      .article-first-action{
        span{
          margin-right: 20px;
          cursor: pointer;
          color: var(--themeColor);
          &:nth-child(3){
            color: #20AB6A;
          }
        }
        a{
          color: #7D818A;
          margin-left: 20px;
          text-decoration: none;
        }
      }
    }
    .article-second-row{
      padding: 10px 46px 20px;
      display: flex;
      .label-title{
        color: #7D818A;
      }
      >div:first-child{
        margin-right: 64px;
      }
    }
  }
}
</style>
